import { useEffect, useState } from 'react';
import cn from 'classnames';
import { ContentfulCardValueProposition } from '@ts/contentful';
import { Flex, ImageTitleTextStack, InteractiveCard } from '@components';
import { Body, Heading } from '@components/typography';
import { useHasMounted } from '@utils/hooks';
import client from '@services/contentful/client';
import { normalizeContentfulEntry } from '@utils/normalizers';
import styles from './CardValueProposition.module.scss';

const CardValueProposition = ({ header, description, interactiveCard, summaryCards, shouldExcludeCopy = false, noPadding = false, interactiveCardChildren, summaryCardsChildren }: ContentfulCardValueProposition) => {
	const classes = cn(styles.container, {
		[styles['container--no-padding']]: noPadding,
	});
	const [buttonModalContent1, setButtonModalContent1] = useState(interactiveCard?.buttonModalContent1);
	const isMounted = useHasMounted();

	useEffect(() => {
		if (isMounted && interactiveCard?.buttonModalContent1.sys) {
			(async () => {
				const modalEntry = await client.getEntry(interactiveCard.buttonModalContent1.sys.id, { include: 2 });
				setButtonModalContent1(await normalizeContentfulEntry(modalEntry));
			})();
		}
	}, [isMounted, interactiveCard?.buttonModalContent1]);

	if (!isMounted) return null;

	const interactiveCardProps = interactiveCard && {
		title: interactiveCard.title,
		description: interactiveCard.text.content[0].content[0].value,
		background: interactiveCard.background?.url ?? null,
		shouldExpand: interactiveCard.shouldExpand,
		shouldDisplayTitle: interactiveCard.shouldDisplayTitle,
		extraHeaderClassName: styles['interactive-card-header'],
		...(interactiveCard.buttonLabel1 || interactiveCard.buttonLink1 ? {
			button1: {
				label: interactiveCard.buttonLabel1,
				link: interactiveCard.buttonLink1,
				color: interactiveCard.buttonColor1,
				modalContent: buttonModalContent1,
			},
		} : {}),
		...(interactiveCard.buttonLabel2 ? {
			button2: {
				label: interactiveCard.buttonLabel2,
				link: interactiveCard.buttonLink2,
				color: interactiveCard.buttonColor2,
			},
		} : {}),
	};

	return (
		<Flex column className={classes}>
			{!shouldExcludeCopy && (
				<Flex column className={styles.copy}>
					<Heading tag='h3' className={styles.heading}>{header}</Heading>
					{description && <Body className={styles.description}>{description}</Body>}
				</Flex>
			)}
			<Flex gap={3}>
				<Flex className={styles['interactive-card-container']}>
					{interactiveCard && <InteractiveCard className={styles['interactive-card']} {...interactiveCardProps} />}
					{!!interactiveCardChildren && <div className={styles['interactive-card-ctfl']}>{interactiveCardChildren}</div>}
				</Flex>
				<Flex gap={3} className={styles['summary-cards-container']}>
					{summaryCards && summaryCards.map((card, index) => (
						<div className={styles['card']} data-index={index} key={card.heading}>
							<ImageTitleTextStack type='Summary' {...card} />
						</div>
					))}
					{!!summaryCardsChildren && <div className={styles['summary-cards-container-ctfl']}>{summaryCardsChildren}</div>}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default CardValueProposition;
