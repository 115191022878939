import { Fragment } from 'react';
import cn from 'classnames';
import Balancer from 'react-wrap-balancer';
import { Flex } from '@components';
import { Body, Heading, Paragraph } from '@components/typography';
import { ImageTitleTextStackProps } from '@ts/index';
import styles from './ImageTitleTextStack.module.scss';

const ImageTitleTextStack = ({
	contentType,
	description,
	heading,
	image,
	href,
	maxWidth = 'Auto',
	type = 'Default',
	index,
	className: customClassName,
}: ImageTitleTextStackProps) => {
	const isVideo = image?.contentType === 'video/mp4';
	const hasContent = (heading !== ' ' && !!heading) || !!description;

	const className = cn(styles.container, customClassName, styles[`max-width-${maxWidth.toLowerCase()}`], {
		[styles['container--summary']]: type === 'Summary',
	});
	const Description = type === 'Summary' ? Paragraph : Body;
	const DescriptionWrapper = type === 'Summary' ? Fragment : Balancer;

	return (
		<Flex
			column
			align='center'
			justify='center'
			className={className}
			data-component-type={contentType}
			data-index={index}
		>
			<div className={href ? styles['is-clickable'] : styles['full-width']} onClick={() => href && window.open(href, '_self')}>
				{isVideo ? (
					<video
						autoPlay
						playsInline
						loop
						muted
					>
						<source src={image.url} />
					</video>
				) : (
					<div className={maxWidth === 'Auto' ? styles['image-container'] : styles['custom-image-container']}>
						<img
							src={image.url}
							alt={image.title}
							width={image.width}
							height={image.height}
							loading='lazy'
						/>
					</div>
				)}
			</div>
			{
				hasContent && (
					<div className={styles['content']}>
						<Heading tag='h6' className={styles['heading']}>
							{heading}
						</Heading>
						{description && <DescriptionWrapper><Description>{description}</Description></DescriptionWrapper>}
					</div>
				)
			}
		</Flex>
	);
};

export default ImageTitleTextStack;
