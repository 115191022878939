import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Accordian } from '@components';
import { ContentfulComponentMap } from '@components/utils';
import styles from './ComponentAccordion.module.scss';

type ComponentAccordionProps = {
	title: string;
	slug: string;
	open: boolean;
	showIcons: boolean;
	content: unknown[];
	children?: React.ReactNode;
	className?: string;
};

const ComponentAccordion = ({ title, content, open, showIcons, children, className }: ComponentAccordionProps) => {
	const [isOpen, setIsOpen] = useState(open);
	const onClick = () => setIsOpen(!isOpen);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	return (
		<div className={cn(className, styles.container)}>
			<Accordian
				title={title}
				isOpen={isOpen}
				onClick={onClick}
				customRow={cn(styles.header, { [styles['without-icons']]: !showIcons })}
			>
				<div className={styles.cards}>{content ? <ContentfulComponentMap blocks={content} /> : null}</div>
				{children && <div style={{ width: '100%', height: '100%' }}>{children}</div>}
			</Accordian>
		</div>
	);
};

export default ComponentAccordion;
