import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { BASE_FRAME_NAMES, DEFAULT_DEMOS, findVisibleVariants, getBasePLPProps, getShopifyIdFromBase64, groupBy, isEmpty, PRODUCT_TYPES } from '@utils/index';
import { normalizeSingleCollection } from '@utils/normalizers';
import { NormalizedCollection, NormalizedProduct, NormalizedVariant } from '@ts/product';
import { Button, Container, Flex, Grid, ProductGrid, VerticalCard } from '@components';
import { useLocalStorage } from '@utils/hooks';
import { getMultipleCollections } from '@services/shopify/operations/get-multiple-collections';
import { ComponentProductList } from '@ts/index';
import styles from './ProductList.module.scss';

const ProductList = ({ collectionHandle, limit, overrideWithSunBaseFrames = false }: ComponentProductList) => {
	const { locale } = useRouter();
	const [activeFrame] = useLocalStorage<typeof BASE_FRAME_NAMES[number]>('activeFrame', 'Larkin');
	const [handle, setHandle] = useState('');
	const [variants, setVariants] = useState<{ [key: string]: Array<NormalizedVariant> }>({});
	const [hasAvailableProducts, setHasAvailableProducts] = useState(null);
	const [collectionProductType, setCollectionProductType] = useState<'base-frames' | 'top-frames'>(null);
	const [collection, setCollection] = useState<NormalizedCollection>(null);

	useEffect(() => {
		(async () => {
			let collection = null;
			if(!!overrideWithSunBaseFrames) {
				const demo = "all" as (typeof DEFAULT_DEMOS)[number];
				const plpProps = await getBasePLPProps({ category: 'sunglasses', demo, locale });
				collection = plpProps.collection;
				const limitedProducts = limit ? collection.products.slice(0, limit) : collection.products;
				collection.products = limitedProducts;
			} else {
				const shopifyCollectionId = getShopifyIdFromBase64(collectionHandle);
				const collectionDataToNormalize = await getMultipleCollections(`(id:${shopifyCollectionId})`);
				collection = normalizeSingleCollection(collectionDataToNormalize[0]);
			}

			// Base Frame
			if (collection.products[0].type.includes(PRODUCT_TYPES.BASE_FRAME)) {
				setCollection(collection);
				setVariants({ UNUSED: collection.products[0].variants });
				setCollectionProductType('base-frames');
			} else {
				// Top Frame
				const variants = findVisibleVariants(collection, activeFrame);
				const limitedProducts = limit ? variants.slice(0, limit) : variants;
				setHandle(collection.handle);
				setVariants(groupBy(limitedProducts, ''));
				setHasAvailableProducts(limitedProducts.length > 0);
				setCollectionProductType('top-frames');
			}
		})();
	}, [activeFrame, collectionHandle, limit]);

	if(isEmpty(variants)) {
		return <SkeletonProductList limit={limit} />;
	}

	return collectionProductType === 'top-frames' ? (
		<>
			<Grid>
				{hasAvailableProducts &&
					Object.entries(variants).map(([key, variants]) => {
						return (
							<ProductGrid key={key} type='tops'>
								{variants.map(variant => (
									<VerticalCard
										key={variant.handle}
										product={variant.product as NormalizedProduct}
										variant={variant}
									/>
								))}
							</ProductGrid>
						);
					})}
			</Grid>
			{limit && <Button href={`/top-frames/${handle}`} color='white' label='View More' padding='1rem 3.2rem' />}
		</>
	) : (
		<Grid>
			{
				<ProductGrid type='base' style={{ width: '100%' }}>
					{collection.products.map(p => (
						<VerticalCard
							key={p.handle}
							product={p}
							variant={p.variants[0]}
							primaryAction='view'
							showVariantControls
							showLensController={!!overrideWithSunBaseFrames}
						/>
					))}
				</ProductGrid>
			}
		</Grid>
	);
};

const SkeletonButton = () => {
	return (
		<Flex justify='center' fullWidth className={styles['skeleton-button-container']}>
			<Container className={styles['skeleton-button']} />
		</Flex>
	);
};

export const SkeletonProductList = ({ limit }) => {
	const defaultLimit = 15;
	return (
		<>
			<Container className={styles['skeleton-product-list']}>
				{Array(limit ?? defaultLimit)
					.fill(true)
					.map((_, index) => (
						<SkeletonTopFrame key={index} />
					))}
			</Container>
			{limit && <SkeletonButton />}
		</>
	);
};

const SkeletonTopFrame = () => {
	return <Container className={styles['skeleton-top-frame']} />;
};

export default ProductList;
