import { Button, Flex } from '@components';
import { TypographyButton, Heading } from '@components/typography';
import { ContentfulButtonFields } from '@ts/contentful';
import ContentfulButton from '../../contentful/ContentfulButton/ContentfulButton';
import styles from './MarketingBlock.module.scss';

type MarketingBlockProps = {
	buttonText: string;
	buttonUrl: string;
	header: string;
	imageSrc: string;
	subText: string;
	className?: string;
	button?: {
		fields: ContentfulButtonFields;
	};
	buttons?: ContentfulButtonFields[];
	blockIndex?: number;
	children?: React.ReactNode;
};

const MarketingBlock = ({
	buttonText,
	buttonUrl = '',
	header,
	imageSrc,
	subText,
	className,
	button,
	buttons = undefined,
	blockIndex,
	children,
}: MarketingBlockProps) => {
	const { fields } = button || {};

	return (
		<Flex
			column
			className={`marketingBlockContainer-${blockIndex} ${styles.container} ${className}`}
			style={{ backgroundImage: `url(${imageSrc})` }}
			data-marketing-block={header}
		>
			{children && <div style={{ width: '100%', height: '100%' }}>{children}</div>}
			<Flex column className={styles.content}>
				<Heading className={`marketingBlock-${blockIndex}-heading`} tag='h3'>
					{header}
				</Heading>
				{subText && <TypographyButton>{subText}</TypographyButton>}
				{buttons ? (
					<Flex gap={4} className={styles['buttons-container']}>
						{buttons.map((b, i) => (
							<ContentfulButton
								key={`text-${i}`}
								text={b.text}
								link={b.link}
								size={b.size}
								// TODO: fix hard-coded color
								color='white'
								event={b.event}
								fullWidth={b.fullWidth}
								extraClasses={`marketingBlock-${blockIndex}-button-${i} ${styles.button}`}
							/>
						))}
					</Flex>
				) : fields ? (
					<ContentfulButton
						text={fields.text}
						link={fields.link}
						size={fields.size}
						color={fields.color}
						event={fields.event}
						fullWidth={fields.fullWidth}
						extraClasses={`marketingBlock-${blockIndex}-button-0 ${styles.button}`}
					/>
				) : (
					!children && (
						// TODO: fix hard-coded color
						<Button
							extraClasses={`marketingBlock-${blockIndex}-button-0 ${styles.button}`}
							size='small'
							href={buttonUrl}
							color='white'
						>
							{buttonText}
						</Button>
					)
				)}
			</Flex>
		</Flex>
	);
};

export default MarketingBlock;
