import React from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Button } from '@components';
import { Heading } from '@components/typography';
import { ContentfulRichTextRenderer } from '@components/utils';
import { LocationCardFields } from '@ts/index';
import styles from './LocationCard.module.scss';

const LocationCard = ({
	title,
	buttonLabel,
	buttonLink,
	address,
	className,
}: LocationCardFields) => {
	const { query } = useRouter();
	const utmParams = {};
	const url = new URL(buttonLink);

	if (query.slug === 'store-locator') {
		Object.entries(query).forEach(([key, value]) => {
			if (key.includes('utm_')) {
				utmParams[key] = value;
				url.searchParams.set(key, value as string);
			}
		});
	}

	return (
		<div className={cn(styles.container, className)}>
			<div>
				<Heading tag='h6' removeDefaultMargin> {title} </Heading>
				<div className={styles.address}>
					{ContentfulRichTextRenderer(false, { text: address })}
				</div>
			</div>
			<Button color='white' size='medium' fullWidth label={buttonLabel} href={url.href} target='_blank' />
		</div>
	);
};

export default LocationCard;
