import React from 'react';
import cn from 'classnames';
import { Body, Heading } from '@components/typography';
import { Flex } from '@components';
import { ContentfulFullWidthTextSection } from '@ts/contentful';
import styles from './FullWidthTextSection.module.scss';

type FullWidthTextSectionProps = ContentfulFullWidthTextSection & {
	page: string;
	className?: string;
	backgroundImage?: string;
	children?: React.ReactNode;
};

const FullWidthTextSection = ({
	alignment,
	heading,
	text,
	textColorNew,
	backgroundColorNew,
	contentType,
	page,
	className,
	backgroundImage,
	children,
}: FullWidthTextSectionProps) => {
	return (
		<Flex
			column
			fullWidth
			data-alignment={alignment}
			style={{
				...(backgroundColorNew ? { backgroundColor: backgroundColorNew } : {}),
				...(textColorNew ? { color: textColorNew } : {}),
				...(backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}),
			}}
			className={cn(styles.container, className)}
			data-component-type={contentType}
			data-page-type={page}
		>
			{children && children}
			{!children && (
				<>
					<Heading tag='h2'>{heading}</Heading>
					<Body style={{ maxWidth: '25em' }}>{text}</Body>
				</>
			)}
		</Flex>
	);
};

export default FullWidthTextSection;
